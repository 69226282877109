


























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup(_, { root: { $router } }) {
    const handleBtnRouter = () => {
      $router.push({ name: 'MobileSearch' })
    }
    return {
      handleBtnRouter,
    }
  },
})
